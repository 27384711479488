@import "partials/variables";
body{
  font-family: 'Montserrat', sans-serif;
}
a{
  transition: 0.5s;

}
img{
  max-width: 100%;
}
button:focus{
    outline:none
}



.lr-space{
  margin-right: 5%;
  margin-left: 5%;
}
.tb-space{
  margin-top: 70px;
  margin-bottom: 70px;
}

.header-area{
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;

  .navigation-area{
    ul{
      margin:0;
      padding: 0;
      list-style: none;
      li{
          display: inline;
          margin: 0 20px;
        a{
         font-size: 20px;
         color: $secondary-color1;
         font-weight: 300;
         text-decoration: none;
         display: inline-block;
         &:hover{
           color:$primary-color2;
         }
        }
        &:last-child{
          a{
            color: $primary-color2;
            border: 1px solid $primary-color2;
            padding: 5px 20px;
            border-radius: 5px;
          }
          margin-right:0;
        }
      }
    }
  }
}

.banner-section{
  position: relative;
  height: 92vh;
  img{
    width: 100%;
  }
  .overlay_text{
    position: absolute;
    top: 30%;
    left: 15%;
    transform: translate(0,-30%);
    h2{
      font-size: 55px;
      font-weight: 700;
      margin-bottom: 30px;
    }
    p{
      font-size: 20px;
    }
    .btn-book-now{
      background-color: $primary-color2;
      color: $secondary-color2;
      text-decoration: none;
      padding: 12px 20px;
      display: inline-block;
      font-size: 20px;
      border-radius: 5px;
      margin-top: 40px;
    }
  }
}

.brands-area{
  background-color: $primary-color3;
  padding: 50px 0;
  .owl-nav{
    color: $primary-color2;
    button.owl-next{
      font-size: 30px !important;
      position: absolute;
      top: 50%;
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
      right: 0;
      &.disabled{
        color: $primary-color1;
      }
    }
    button.owl-prev{
      font-size: 30px !important;
      position: absolute;
      top: 50%;
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
      margin-left: -50px;
      left: 0;
      &.disabled{
        color: $primary-color1;
      }
    }
  }
}

.brands-inner-section{
  .single-brand{
    img{

    }
  }
}

.promotion-area{
  padding: 140px 0 80px;
  .single-promo{
    padding: 50px 14px 14px 14px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    &:hover{
      background-color: $primary-color2;
      color: $secondary-color2;
      border-radius: 20px;
      position: relative;
      .single-promo-icon{
        color: $primary-color2;
        background-color:  $secondary-color2;
        border: 1px solid #ddd;
      }
    }
    &.default-orange{
      background-color: $primary-color2;
      color: $secondary-color2;
      border-radius: 20px;
      position: relative;
      .single-promo-icon{
        color: $primary-color2;
        background-color:  $secondary-color2;
        border: 1px solid #ddd;
      }
    }
  }
  .single-promo-icon{
    background-color: $primary-color1;
    color: $secondary-color2;
    width: 70px;
    height: 70px;
    border-radius: 500px;
    margin: 0 auto;
    padding: 20px 0;
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translate(-50%,0);
    -moz-transform: translate(-50%,0);
    -webkit-transform: translate(-50%,0);
    -ms-transform: translate(-50%,0);
    -o-transform: translate(-50%,0);
    i{
        font-size: 30px;
    }
  }
  .promo-title{
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  p{
    font-weight: 300;
  }
}

.offer-area{
  background-color: $primary-color3;
  padding: 50px 0 200px;
  position: relative;
  &:before{
    content: "";
    position: absolute;
    background-color: $secondary-color2;
    height: 145px;
    width: 100%;
    bottom: 0;
    left: 0;
  }
}

.offer-inner-area{
  position: relative;

  .insurance-offer{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    img{
      border-radius:20px;
    }
  }
  .cars{
    position: absolute;
    bottom: -155px;
    left: -165px;
  }
}


.car-selection-area{
  margin-bottom: 70px;
}
.section-title{
  position: relative;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 70px;
  text-transform: uppercase;
  span{
    position: relative;
    &:before{
      position: absolute;
      content: "";
      height: 5px;
      width: 30px;
      background-color: $secondary-color1;
      left: -50px;
      top: 50%;
      transform: translate(0,-50%);
      -moz-transform: translate(0,-50%);
      -webkit-transform: translate(0,-50%);
      -ms-transform: translate(0,-50%);
    }
    &:after{
      position: absolute;
      content: "";
      height: 5px;
      width: 30px;
      background-color: $secondary-color1;
      right: -50px;
      top: 50%;
      //transform: translate(0,-50%);
      //-moz-transform: translate(0,-50%);
      //-webkit-transform: translate(0,-50%);
      //-ms-transform: translate(0,-50%);
    }
  }

}

.single-car-select-box{
  box-shadow: 0 0 6px #ddd;
  margin-bottom: 50px;
  padding: 0 0 10px 0;
  border-radius: 20px;
  border-left: 2px solid $primary-color3;
  border-right: 2px solid $primary-color3;
  border-bottom: 2px solid $primary-color3;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  display: none;
  &:hover{
    border-left: 2px solid $primary-color2;
    border-right: 2px solid $primary-color2;
    border-bottom: 2px solid $primary-color2;
    .single-car-select-box-title-img{
      background-color: $primary-color2;
      border-radius: 20px 20px 0 0;
      position: relative;
      z-index: 2;
      &:after{
        content:"";
        background: $secondary-color2;
        height: 80px;
        width: 100%;
        bottom: 0;
        left: 0;
        position: absolute;
        z-index: 1;
      }
      h2{
        background-color: $primary-color2;
        color: $secondary-color2;
        border-radius: 20px 20px 0 0;
      }
      img{
        transform: scale(1.1);
        -moz-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
      }
    }
    .select-car-button{
      color:$secondary-color2;
      background-color: $primary-color2;
    }
  }
  .single-car-select-box-title-img{
    position: relative;
    z-index: 2;
    h2{
      font-size: 22px;
      font-weight: 700;
      background-color: $primary-color3;
      padding: 15px 10px;
      border-radius: 20px 20px 0 0;
    }
    img{
      margin: 30px 10px;
      position: relative;
      z-index: 2;
    }
  }
  .single-car-features{
    overflow: hidden;
    padding: 0 30px;
    ul{
      margin: 0;
      padding: 0;
      list-style: none;
      li{
        float: left;
        width: 50%;
        margin: 14px 0;
        text-align: left;
        padding-left: 30px;
        font-weight: 300;
        &:nth-child(1){
          position: relative;
          &:before{
            background: url('.././img/home-page/car-selection/icons/car.svg');
            content: "";
            position: absolute;
            width: 25px;
            height: 17px;
            top:0;
            left: 0;
          }
        }
        &:nth-child(2){
          position: relative;
          &:before{
            background: url('.././img/home-page/car-selection/icons/iconmonstr-dashboard-5.svg');
            content: "";
            position: absolute;
            width: 25px;
            height: 20px;
            top:0;
            left: 0;
          }
        }
        &:nth-child(3){
          position: relative;
          &:before{
            background: url('.././img/home-page/car-selection/icons/Group 300.svg');
            content: "";
            position: absolute;
            width: 25px;
            height: 23px;
            top:0;
            left: 0;
          }
        }
        &:nth-child(4){
          position: relative;
          &:before{
            background: url('.././img/home-page/car-selection/icons/iconmonstr-gear-1.svg');
            content: "";
            position: absolute;
            width: 25px;
            height: 23px;
            top:0;
            left: 0;
          }
        }
      }
    }
  }
  .select-car-button{
    border:2px solid $primary-color2;
    color: $primary-color2;
    padding: 10px 25px;
    display: inline-block;
    font-size: 16px;
    margin-top: 30px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 700;
  }
}
.see-all-button{
  text-align: center;
  .see-all-button-anchor{
    color:$secondary-color2;
    background-color: $primary-color2;
    padding: 10px 25px;
    display: inline-block;
    font-size: 16px;
    margin-top: 30px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 700;
    &.noContent{
      background-color: $primary-color3;
      color: $secondary-color1;
      cursor: not-allowed;
    }
  }
}


.quick-access{
  background-color: $primary-color3;
  padding: 50px 0;
  position: relative;
  &:before{
    content: "";
    position: absolute;
    background-color: #ffffff;
    height: 85px;
    width: 100%;
    bottom: 0;
    left: 0;
  }
  .steps-list{
    margin-top: 20px;
    margin-left: 10px;
    position: relative;
    .lambo-car{
      position: absolute;
      bottom: -80px;
      right: -200px;
    }
    ul{
      margin: 0;
      padding: 0;
      list-style: none;
      li{
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 40px;
        position: relative;
        padding: 10px 0 0 70px;
        &:nth-child(1){
          &:before{
            background: url('.././img/home-page/easy-access-section/icons/download.png');
            content: "";
            position: absolute;
            width: 60px;
            height: 55px;
            top:0;
            left: 0;
          }
        }
        &:nth-child(2){
          &:before{
            background: url('.././img/home-page/easy-access-section/icons/car.png');
            content: "";
            position: absolute;
            width: 60px;
            height: 55px;
            top:0;
            left: 0;
          }
        }
        &:nth-child(3){
          &:before{
            background: url('.././img/home-page/easy-access-section/icons/key.png');
            content: "";
            position: absolute;
            width: 60px;
            height: 55px;
            top:0;
            left: 0;
          }
        }
      }
    }
  }
}


.testimonials-carousals-area {
  position: relative;

  .testimonials-carousals-inner {
    .active {
      &.center {
        .single-testimonials {
          opacity: 1;
          box-shadow: 0 0 20px #ddd;
          border-radius: 20px;
          h3 {
            &.author-name {
              color: $primary-color2;
            }
            p {

            }
          }
        }
      }
    }
    .single-testimonials {
      opacity: 0.5;
      padding: 90px 40px 50px;
      margin: 110px 0 20px 0;
      position: relative;
      .author-thum {
        position: absolute;
        top: -95px;
        left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
      }
      img {
        width: auto;
        margin: 0 auto;
      }
      h3 {
        &.author-name {
          font-size: 30px;
          font-weight: 700;
          margin: 25px 0;
        }
        p {
          font-size: 20px;
        }
      }
    }
  }
  .owl-dots {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    bottom: 40px;
    .owl-dot {
      width: 10px;
      height: 10px;
      background: #ddd;
      border-radius: 500px;
      margin: 0 10px;
      &.active {
        width: 30px;
        background-color: $primary-color2;
      }
    }
  }
  .owl-nav {
    button.owl-next {
      position: absolute;
      top: 60%;
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      background-color: $primary-color2;
      color: $secondary-color2;
      right: 25%;
      width: 50px;
      height: 50px;
      font-size: 30px;
      border-radius: 500px;
    }
    button.owl-prev {
      position: absolute;
      top: 60%;
      -ms-transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      left: 25%;
      background-color: $primary-color2;
      color: $secondary-color2;
      width: 50px;
      height: 50px;
      font-size: 30px;
      border-radius: 500px;
    }
  }
}

.about-suprim-area{
  padding: 100px 0;
  position: relative;
  &:before{
    content: '';
    bottom: 0;
    height: 280px;
    width: 100%;
    background-color: $primary-color2;
    position: absolute;
    left: 0;
  }
  .title-image{
    h2{
      font-size: 50px;
      font-weight: 800;
      text-transform: uppercase;
    }
    p{

    }
    .single-image{
      margin-top: 50px;
      position: relative;
      z-index: 2;
      img{

      }
    }
  }
  .about-details{
    background-color: $primary-color3;
    padding: 30px 40px 0;
    margin-top: 70px;
    position: relative;
    z-index: 1;
    &:before{
      position: absolute;
      top:0;
      left:-95px;
      content: "";
      width: 100px;
      height: 100%;
      background-color: $primary-color3;
      z-index: 2;
    }
    h2.section-title{
      font-size: 25px;
    }
    p{
      font-size: 16px;
      margin-bottom: 65px;
    }
    .list-section{
      overflow: hidden;
      .single-list-section{
        overflow: hidden;
        margin-bottom: 30px;
        .left-list{
          float: left;
          width: 30px;
          text-align: left;
          font-size: 30px;
          color: $primary-color2;
          font-weight: 700;
        }
        .right-list{
          float: right;
          width:90%;
          p{
            margin-bottom: 0;
            line-height: 30px;
          }
        }
      }
    }
  }
}

.footer-area{
  position: relative;
  &:before{
    content: '';
    position: absolute;
    width: 100%;
    height: 50%;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
    background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
    top: 0;
    left: 0;
    z-index: 1;
  }
  &:after{
    content: '';
    position: absolute;
    width: 100%;
    height: 30%;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+0,000000+0,000000+72&0+1,0+1,0.65+33 */
    background: -moz-linear-gradient(top, rgba(71,78,89,0) 0%, rgba(71,78,89,0.1) 1%, rgba(71,78,89,1) 33%, rgba(71,78,89,1) 72%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(71,78,89,0) 0%,rgba(71,78,89,0.1) 1%,rgba(71,78,89,1) 33%,rgba(71,78,89,1) 72%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(71,78,89,0) 0%,rgba(71,78,89,0.1) 1%,rgba(71,78,89,1) 33%,rgba(71,78,89,1) 72%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}

.founder-info{
  width: 700px;
  margin: 0 auto;
  padding: 100px 0;
  position: relative;
  z-index: 2;
  img{}
  h2.founder-name{
    font-size: 30px;
    font-weight: 700;
  }
  h4.founder-designation{
    font-size: 22px;
    font-weight: 700;
    color: $primary-color2;
  }
  .founder-description{
    font-size: 20px;
    margin: 40px 0;
    position: relative;
    &:before{
      position: absolute;
      content: "";
      top: -15px;
      left: -30px;
      background: url('.././img/home-page/about-section/left.png');
      width: 32px;
      height: 31px;
    }
    &:after{
      position: absolute;
      content: "";
      bottom: -15px;
      right: -10px;
      background: url('.././img/home-page/about-section/right.png');
      width: 32px;
      height: 24px;
    }
  }
}
.drive-dream-section{
  position: relative;
  z-index: 2;
  background-color:  $primary-color2;
  padding: 40px 50px;
  border-radius: 10px;
  margin-bottom: 100px;
  .drive-dream-content{
    color: $secondary-color2;

    h2{

      font-weight: 700;
    }
    p{
      font-weight: 300;
    }
  }
  .get-started-button{
    margin-top: 25px;
    a{
      color: $primary-color2;
      background-color: $secondary-color2;
      display: inline-block;
      padding: 15px 20px;
      font-size: 13px;
      font-weight: 700;
      text-decoration: none;
      border-radius: 5px;

    }
  }
}
.social-icon-area{
  margin-bottom: 30px;
  position: relative;
  z-index: 2;
  ul{
    margin: 0;
    padding: 0;
    list-style: none;
    li{
      display: inline;
      a{
        display: inline-block;
        color: $secondary-color2;
        font-size: 20px;
        padding: 0 10px;
        &:hover{
          color: $primary-color2;
        }
        img{
          width: 100px;
        }
      }
    }
  }
}
.footer-menu{
  position: relative;
  z-index: 2;
  margin-bottom: 30px;
  ul{
    margin: 0;
    padding: 0;
    list-style: none;
    li{
      display: inline;
      a{
        display: inline-block;
        font-size: 14px;
        margin-right: 10px;
        color: $secondary-color2;
        font-weight: 300;
        text-decoration: none;
        &:hover{
          color: $primary-color2;
        }
      }
    }
  }
}
.footer-copy-right{
  position: relative;
  z-index: 2;
  margin-bottom: 30px;
  p{
    color: $secondary-color2;
    font-size: 14px;
    font-weight: 300;
  }
}


.mobile-icon{
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 2;
  cursor: pointer;
  display: none;
  div{
    margin-bottom: 8px;
    &.first{
      background-color: $secondary-color1;
      height: 3px;
      width: 30px;
    }
    &.second{
      background-color: $secondary-color1;
      height: 3px;
      width: 10px;
    }
    &.third{
      background-color: $secondary-color1;
      height: 3px;
      width: 20px;
    }
  }
}
.mobile-menu-nav{
  display: none;
}


/*Car Details page*/
.car-details-footer{
  margin-top: 50px;
  &:before{
    display: none;
  }
  &:after{
    background-color: $primary-color1;
    height: 70%;
  }

  .drive-dream-section{
    margin-bottom: 50px;
  }
}

.car-details-hero-area{
  position: relative;
  .car-bigger-image-thumbnails{
    .single-car-picture{
      height: 92vh;
    }
  }
  .car-smaller-thum {
    position: absolute;
    bottom: 0;
    width: 100%;

  }
  #thumbs{
    background: rgba(0,0,0,1);
    padding: 35px 70px;
  }
  .overlay_text{
    position: absolute;
    top: 30%;
    left: 15%;
    transform: translate(0,-30%);
    -moz-transform: translate(0,-30%);
    -webkit-transform: translate(0,-30%);
    z-index: 9;
    color: $secondary-color2;
    h2{
      font-size: 55px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    .btn-book-now{
      background-color: $primary-color2;
      color: $secondary-color2;
      text-decoration: none;
      padding: 12px 20px;
      display: inline-block;
      font-size: 20px;
      border-radius: 5px;
      margin-top: 40px;
    }
  }
}


.car-price-details-area{
  margin: 70px 0;
  .car-specification{
    h2{
      color:$secondary-color1;
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 30px;
    }
    p{
      font-weight: 300;
    }
  }
  .single-specification{
    margin-top: 30px;
    img{
      width: 50px;
      &.alignleft{
        float: left;
        margin-right: 15px;
      }
    }
    .spec-details{

      h4{
        font-weight: 700;
        margin-bottom: 0;
      }
      h6{
        margin-top: 0;
        font-weight: 400;
      }
    }
  }
  .car-box-detail{
    border: 2px solid$primary-color2;
    padding: 25px 40px;
    border-radius: 10px;
    img{
      margin: 30px 0;
    }
    ul{
      margin: 0;
      padding: 0;
      list-style: none;
      li{
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 15px;
        position: relative;
        &:before{
          position: absolute;
          content: "";
          border: 1px dotted$secondary-color1;
          height: 1px;
          width: 100%;
          top: 11px;
          z-index: -1;
        }
        &:after{
          position: absolute;
          content: "\f058";
          right: 0;
          font-family: FontAwesome;
          z-index: 1;
          background: $secondary-color2;
          padding: 0 5px;
          color: #85E140;
        }
        span{
          background: $secondary-color2;
          padding-right: 10px;
        }
      }
    }
    .total-cost{
      text-align: center;
      overflow: hidden;
      width: 240px;
      margin: 25px auto;
      h2{
        font-weight: 700;

        span{

          &#total_cost_{
            font-size: 20px;
            float: left;
            margin-top: 10px;
            margin-right: 10px;
          }
          &#total_amount_numaric{
            font-size: 40px;
            float: left;
          }
        }
      }

    }
    .btn-book-now{
      background-color: $primary-color2;
      color: $secondary-color2;
      text-decoration: none;
      padding: 12px 20px;
      display: block;
      font-size: 20px;
      border-radius: 10px;
      margin-top: 40px;
      text-align: center;
    }
  }
}










// Mixins and Placeholders
%clearfix {
  &:after, &:before {
    content: '';
    display: block;
    width: 100%;
    clear: both;
  }
}

@mixin prefix($prop, $val) {
  @each $prefix in '-webkit-', '-moz-', '-ms-', '' {
    #{$prefix}#{$prop}: $val;
  }
}
*, *:before, *:after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}



.how-it-works-area{
  background: $primary-color3;
  padding: 60px 0;
  .how-it-works-inner{
    .timeline-icon {
      background: $secondary-color2;
      width: 50px;
      height: 50px;
      position: absolute;
      top: 50%;
      left: 50%;
      overflow: hidden;
      margin-left: -23px;
      margin-top: -23px;
      @include prefix(border-radius, 50%);

    }
    .timeline-icon{
      h4{
        font-weight: 700;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        left: 50%;
        margin: 0;

      }
    }

    .timeline-content {
      width: 45%;
      background: #fff;
      padding: 30px 20px;
      @include prefix(border-radius, 10px);
      @include prefix(transition, all .3s ease);
      overflow: hidden;
      .single-content-timeline{
        float: right;
        width: 90%;
      }
      h2 {
        font-weight: 700;
        color: $primary-color2;
        font-size: 20px;
        margin-top: 0;
      }
      p{
        font-weight: 300;
      }
      &:before {
        content: '';
        position: absolute;
        left: 45%;
        top: 50%;
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left:15px solid $secondary-color2;
        transform: translate(0,-50%);
        -moz-transform: translate(0,-50%);
        -webkit-transform: translate(0,-50%);
      }

      &.right {
        float: right;

        &:before {
          content: '';
          right: 45%;
          left: inherit;
          border-left: 0;
          border-right: 15px solid $secondary-color2;
        }
      }
      .icon-img{
        float: left;
        width: 9%;
        margin-right: 1%;
      }
    }

    // Timeline
    #timeline {
      width: 100%;
      margin: 30px auto;
      position: relative;
      padding: 0 10px;
      @include prefix(transition, all .4s ease);

      &:before {
        content:"";
        width: 3px;
        height: 100%;
        background: $secondary-color1;
        left: 50%;
        top: 0;
        position: absolute;
      }

      &:after {
        content: "";
        clear: both;
        display: table;
        width: 100%;
      }

      .timeline-item {
        margin-bottom: 50px;
        position: relative;
        @extend %clearfix;
      }
    }

  }
}

.testimonals-area-car-details-page{
  padding: 60px 0;
  background-color: $primary-color1;
  color: $secondary-color2;


  h2.section-title{
    span{
      &:before{
        background-color: $secondary-color2;
      }
      &:after{
        background-color: $secondary-color2;
      }
    }

  }

  .all-car-page-testimonials{
    position: relative;
    .single-testimonial-inner{
      background-color: #303743;
      padding: 25px 30px;
      border-radius: 10px;
      position: relative;
      &:before{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 20px solid #303743;
        bottom: -20px;
        left: 40px;
      }
    }
    .single-testimonial{
      .star-marking i{
        color: #FFC400;
        font-size: 20px;
        margin-bottom: 15px;
      }
    }
    .review-content p{
      font-weight: 300;
    }
    .author-name-picture{
      overflow: hidden;
      margin-top: 45px;
    }
    .author-name-picture img{
      width: auto;
      float: left;
      margin-right: 10px;
    }
    .author-name-picture h3{
      font-size: 16px;
      font-weight: 700;
      float: left;
      margin-top: 42px;
    }
  }

  .owl-nav {
    button.owl-next {
      position: absolute;
      top: 28%;
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      background-color: $primary-color2;
      color: $secondary-color2;
      right: -30px;
      width: 50px;
      height: 50px;
      font-size: 30px;
      border-radius: 500px;
      &.disabled{
        background: #959595;
        cursor: not-allowed;
      }
    }
    button.owl-prev {
      position: absolute;
      top: 28%;
      -ms-transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      left: -30px;
      background-color: $primary-color2;
      color: $secondary-color2;
      width: 50px;
      height: 50px;
      font-size: 30px;
      border-radius: 500px;
      &.disabled{
        background: #959595;
        cursor: not-allowed;
      }
    }
  }
}

.other-car-area{
  padding: 60px 0;
  .owl-nav {
    button.owl-next {
      position: absolute;
      top: 50%;
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      background-color: $primary-color2;
      color: $secondary-color2;
      right: -65px;
      width: 50px;
      height: 50px;
      font-size: 30px;
      border-radius: 500px;
    }
    button.owl-prev {
      position: absolute;
      top: 50%;
      -ms-transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      left: -65px;
      background-color: $primary-color2;
      color: $secondary-color2;
      width: 50px;
      height: 50px;
      font-size: 30px;
      border-radius: 500px;

    }
  }

  .single-car-select-box {
    &:hover{
      .single-car-select-box-title-img{
        &:after{
          height: 50px;
        }
      }
    }

    .single-car-select-box-title-img img{
      margin:30px auto;
      max-width: 250px;
    }
    .single-car-features{
      ul{


        li{
          float: none;
          width: 100%;
          margin: 25px 0;
          text-align: left;
          padding-left: 30px;
          font-weight: 300;
          &:nth-child(1){
            position: relative;
            &:before{
              background: url('.././img/car-details-page/other-cars/icons/car.svg');
              content: "";
              position: absolute;
              width: 25px;
              height: 17px;
              top:0;
              left: 0;
            }
          }
          &:nth-child(2){
            position: relative;
            &:before{
              background: url('.././img/car-details-page/other-cars/icons/iconmonstr-dashboard.svg');
              content: "";
              position: absolute;
              width: 25px;
              height: 20px;
              top:0;
              left: 0;
            }
          }
          &:nth-child(3){
            position: relative;
            &:before{
              background: url('.././img/car-details-page/other-cars/icons/group.png');
              content: "";
              position: absolute;
              width: 25px;
              height: 27px;
              top: -6px;
              left: 0;
            }
          }
          &:nth-child(4){
            position: relative;
            &:before{
              background: url('.././img/car-details-page/other-cars/icons/iconmonstr-gear.svg');
              content: "";
              position: absolute;
              width: 25px;
              height: 23px;
              top:0;
              left: 0;
            }
          }
        }

      }
    }
  }

}

.car-details-posts-inner{

  .btn-book-now{
    background-color: $primary-color2;
    color: $secondary-color2;
    text-decoration: none;
    padding: 12px 50px;
    display: inline-block;
    font-size: 18px;
    border-radius: 5px;
    margin-top: 10px;
    font-weight: 700;
    float: right;
  }


  .single_help_section_inner {
    position: relative;
    width: 100%;
    img{
      width:40%;
      position: relative;
      z-index: 2;
    }
  }
  .position-content-car{
    z-index: 1;
    left: 40%;
    position: absolute;
    top: 50%;
    transform: translate(0px, -50%);
    -moz-transform: translate(0px, -50%);
    -webkit-transform: translate(0px, -50%);
    -o-transform: translate(0px, -50%);
    -ms-transform: translate(0px, -50%);
    div.content_single_help {
      background-color: $primary-color3;
      border-radius: 0 20px 20px 0;
      padding: 50px;
    }
    .content_single_help  h2 {
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 20px;
    }
    .content_single_help p {
      color: #4f4c70;
      text-align: justify;
    }
    .btn-book-now{
      margin-right: 0;
    }
  }


  .single_help_section_inner.single_help_section_inner_right {
    text-align: right;
  }
  .single_help_section_inner.single_help_section_inner_right{
    .position-content-car{
      left: 0;
      right: 40%;
      text-align: left;
      z-index: 1;
      div.content_single_help {
        margin-right: 0;
        border-radius: 20px 0 0 20px;
      }
      .btn-book-now{
        margin-right: 20px;
      }
    }
  }

}


/********Merchandise Page*********/
.banner-section-inner.banner-for-merchandise{
  .overlay_text{
    left: inherit;
    right: 15%;
    color: $secondary-color2;
    p{
      display: block !important;
    }
  }
}

.footer-area{
  &.merchandise-footer-area{
    margin-top: 0;
    padding: 50px 0px 20px;
    &:after{
      height: 100%;
    }
  }
}


.new-arrivals-section{
  background-color: $primary-color3;
  padding: 60px 0;
}


.single-product-area{
  overflow: hidden;
  border: 1px solid#ddd;
  border-radius: 20px;
  padding: 20px;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  margin-bottom:30px;
  &:hover{
    background-color: $secondary-color2;
    .product-price-detail{
      h3{
        background-color: #ccc;
      }
      a{
        background-color: $primary-color2;
        color: $secondary-color2;
      }
    }
  }


  .upper-product-images {
    width: 48%;
    float: left;
    margin-right: 2%;
  }

  .upper-product-description {
    float: left;
    width: 48%;
    margin-left: 2%;
  }
}

.product-thumbnails{
  .big-thumbnails{
    img{
      border-radius: 10px;
    }
  }
  .small-thumbnails{
    margin: 10px 0 0;
    ul{
      border: 0;
    }
    .nav-item{
      float: left;
      width: 31.9%;
      margin: 0 1%;
      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
      }
      a.small-thumbnail-item{
        margin: 0;
        padding: 0;
        border-radius:0;
        img{
            max-width: 100%;
          border-radius: 5px;
        }
      }
    }
  }
}

.product-description{
  border-bottom:1px solid#ddd;
  padding-bottom: 10px;
  h4{
    margin-top: 0;
    font-weight: 700;
  }
  h5{
    font-weight: 700;
  }
  p{
    font-weight: 300;
  }

}
.product-price-detail{
  h3{
    overflow: hidden;
    margin: 20px 0 0 0;
    border: 2px solid #ccc;
    padding: 8px 5px;
    border-radius: 5px;
    strong{
      margin-right: 5px;
    }
    span{
      font-size: 12px;

    }
  }
  a{
    margin: 20px 0 0 0;
    border: 2px solid $primary-color2;
    padding: 10px 5px;
    border-radius: 5px;
    color: $primary-color2;
    display: block;
    font-weight: 700;
    text-decoration: none;
  }
}
.suprime-merchandise-area{
  background-color: $secondary-color2;
  .single-product-area{
    &:hover{
      background-color: $primary-color3;
      .product-price-detail{
        h3{
          background-color: $secondary-color2;
          border: 2px solid$secondary-color2;
        }
      }
    }
  }
}
.why-merchandise-area{
  .car-details-posts-inner .btn-book-now{
    float: none;
  }
}
.more-details-area{
  padding: 60px 0;
}


.product-info-area{
  border-top:1px solid#ccc;
  padding-top: 30px;

  .big-thum{
    img{
      width: 100%;
      border-radius: 10px;
    }
  }
  .delivery-info{
    overflow: hidden;
    text-align: center;
    margin: 30px 0;
    .single-delivery-info{
      float: left;
      width: 31.33%;
      margin: 0px 1%;
    }
  }
  .small-thum{
    .single-small-thum{
      margin-bottom: 30px;
      img{
        border-radius: 10px;
      }
    }
  }
  .product-content{
    h2{
      font-weight: 700;
      margin-top: 0px;
      font-size: 40px;
    }
  }

  .product-review{
    overflow: hidden;
    .star-marking{
      float: left;
      i{
        color: #FFC400;
        font-size: 26px;
        margin-right: 5px;
      }
    }
    .product-review-numbers{
      float: left;
      margin-left: 2%;
      font-size: 18px;
    }
  }

  .product-price-stock{
    margin-top:30px;
    overflow: hidden;
    .product-price{
      float: left;
      width: 30%;
      background-color: $primary-color3;
      text-align: center;
      padding: 10px 0px;
      h2{
        margin-bottom: 0;
      }
    }
    .product-stock{
      float: right;
      width: 65%;
      text-align: right;
      h4{
        padding: 12px 0;
        color: $primary-color2;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  .product-details-content{
    margin-top: 30px;
    margin-bottom: 30px;
    h3{
      margin: 0 0 10px 0;
      font-weight: 700;
      font-size: 16px;
    }
    p{

    }

  }
  .product-offers-buy{
    background-color: $primary-color3;
    overflow: hidden;
    border-radius: 5px;
    padding: 16px 20px;
    .product-buy-one-get-one{
      float: left;
      width: 50%;
      h2{
          font-size: 22px;
         margin: 0;
      }
    }
    .product-best-offer{
      float: right;
      width: 48%;
      text-align: right;
      h4{
        margin: 4px 0;
        color: $primary-color2;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  .book-now-area{
    margin-top: 20px;
    margin-bottom: 65px;
    .btn-book-now {
      background-color: $primary-color2;
      color: $secondary-color2;
      text-decoration: none;
      padding: 15px 50px;
      display: block;
      font-size: 18px;
      border-radius: 5px;
      margin-top: 10px;
      font-weight: 700;
      text-align: center;
    }
  }

  .review-prgress-bar{
    .review-progress{
      float: left;
      width: 75%;
      margin-right: 2%;
      margin-bottom: 0;
      margin-top: 13px;
      height: 15px;
      .bg-review-color{
        background-color: #85E140;
      }
    }
    .review-number{
      float: left;
      width: 23%;
      h2{

      }
    }
  }

}


.order-to-discount-area{
  background-color: $primary-color3;
  padding: 60px 0;
  .single-order-box{
    background-color: $secondary-color2;
    border-radius: 5px;
    .single-order-box-inner{
      background-color: $primary-color1;
      color: $secondary-color2;
      border-radius: 5px;
      position: relative;
      h2{
        margin: 0;
        font-size: 21px;
        font-weight: 700;
        padding: 14px 20px;
      }
    }
    .single-order-box-content{
      padding: 30px 20px;
      p{

      }
    }

  }
}




.box__label {
  background-color: $primary-color2;
  color: #fff;
  position: absolute;
  border-radius: 0 3px 3px 3px;
  bottom: auto;
  top: -8px;
  right: 45px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 25px 14px;
  font-size: 18px;
  width: 60px;
}
.box__label:after {
  content: '';
  color: $primary-color2;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  -moz-transform: scale(0.9999);
  -webkit-transform: scale(0.9999);
  transform: scale(0.9999);
  border-width: 0 0 8px 8px;
  border-bottom-color: currentColor;
  position: absolute;
  top: 0;
  right: 100%;
}
.box__label:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 20px solid #fff;
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  left: 19px;
  bottom: -45px;
}
.mobile-delivery-info,.mobile-bigthum{
  display: none !important;

}

@import "partials/responsive";
