.container{transition:all 0.9s ease 0s;-moz-transition:all 0.9s ease 0s;-webkit-transition:all 0.9s ease 0s;-o-transition: all 0.9s ease 0s;}


@media only screen and (min-width: 1356px) and (max-width: 1555px) {

  .quick-access{
    .steps-list{
      .lambo-car{
        position: absolute;
        bottom: -100px;
        right: -80px;
        width: 415px;
      }
    }
  }
  .offer-inner-area{
    .cars{
      bottom: -115px;
      left: -100px;
      width: 415px;
    }
  }
  .banner-section,.car-details-hero-area{
    .overlay_text{
      h2{
        font-size: 35px;
      }
      p{
        font-size: 14px;
      }
    }
  }



}
@media only screen and (min-width: 992px) and (max-width: 1355px) {


  .header-area{
    .navigation-area{
      ul{
        margin:0;
        padding: 0;
        list-style: none;
        li{
          margin: 0 12px;
          a{
            font-size: 16px;
          }
        }
      }
    }

  }

  .banner-section,.car-details-hero-area{
    .overlay_text{
      h2{
        font-size: 35px;
      }
      p{
        font-size: 14px;
      }
    }
  }
  .car-details-hero-area{
    #thumbs{
      padding:25px 35px;
    }
  }

  .quick-access{
    padding: 80px 0;
    &:before{
      height: 115px;
    }
    .steps-list{
      .lambo-car{
        position: absolute;
        bottom: -95px;
        right: -24px;
        width: 310px;
      }
      ul{
        li{
          margin-bottom: 20px;
        }
      }
    }
  }
  .offer-inner-area{
    .cars{
      bottom: -95px;
      left: -24px;
      width: 310px;
    }
  }


  .all-car-page-testimonials{

  .owl-nav {
    button.owl-next {
      right: -20px;
    }
    button.owl-prev {

      left: -20px;

    }
  }
}


  .other-car-area{
    .owl-nav {
      button.owl-next {
        right: -20px;
      }
      button.owl-prev {
        left: -20px;

      }
    }

  }

  .car-details-posts-inner{
    .content_single_help{

      h2{
        margin: 0 0 10px 0;
      }
      p {
        font-size: 12px;
      }
    }
  }



  .product-info-area {
    .product-content h2 {
      font-weight: 700;
      margin-top: 0px;
      font-size: 30px;
    }
    .delivery-info{
      .single-delivery-info{
        h4{
          font-size: 16px;
        }
      }
    }
  }

  .order-to-discount-area{
    .single-order-box{
      .single-order-box-inner{
        h2 {
          font-size: 18px;
        }
      }
    }
  }


}


/* Tablet Layout: 768px. */

@media only screen and (min-width: 768px) and (max-width: 991px) {



  .header-area{
    .navigation-area{
      ul{
        margin:0;
        padding: 0;
        list-style: none;
        li{
          margin: 0 12px;
          a{
            font-size: 14px;
          }
        }
      }
    }
  }
  .banner-section,.car-details-hero-area{
    .overlay_text{
      h2{
        font-size: 24px;
        margin-bottom: 15px;
      }
      p{
        font-size: 12px;
      }
      .btn-book-now {
        margin-top: 15px;
      }
    }
  }
  .quick-access{
    &:before {
      height: 25px;
    }
    .steps-list{
      margin-top: 50px;
      .lambo-car{
        position: absolute;
        bottom: -95px;
        right: 0;
        width: 310px;
      }
      ul{
        li{
          margin-bottom: 20px;
        }
      }
    }
  }
  .offer-inner-area{
    img{
      width: 100%;
    }
    .insurance-offer {
      position: inherit;
      transform: inherit;
      -moz-transform: inherit;
      -webkit-transform: inherit;
      -o-transform: inherit;
      margin-bottom: 10px;

    }
    .cars{
      bottom: -150px;
      left: 0;
      width: auto;
    }
  }

  .promotion-area {
    padding: 90px 0 80px;
  }
  .tb-space {
    margin-bottom: 30px;
  }

  .testimonials-carousals-area {
    position: relative;

    .testimonials-carousals-inner {
      .single-testimonials {
        box-shadow: 0 0 20px #ddd;
        border-radius: 20px;
        padding: 90px 40px 50px;
        margin: 110px 20px 20px 20px;
        position: relative;
        opacity: 1;
        .author-thum {
          position: absolute;
          top: -95px;
          left: 50%;
          transform: translate(-50%, 0);
          -moz-transform: translate(-50%, 0);
          -webkit-transform: translate(-50%, 0);
          -ms-transform: translate(-50%, 0);
          -o-transform: translate(-50%, 0);
        }
        img {
          width: auto;
          margin: 0 auto;
        }
        h3 {
          &.author-name {
            font-size: 30px;
            font-weight: 700;
            margin: 25px 0;
            color: $primary-color2;
          }
          p {
            font-size: 20px;
          }
        }
      }
    }
  }
  .about-suprim-area{
    .about-details{
      &:before {
        position: inherit;
      }
    }
  }
  .brands-area{
    .owl-nav{
      button.owl-next{
        right: 0;
        margin-right: 0;
      }
      button.owl-prev{
        left: 0;
        margin-left: 0;
      }
    }
  }
  .founder-info {
    width: 100%;
  }

  .car-details-hero-area{
    #thumbs {
      padding: 35px 30px;
    }
  }
  .all-car-page-testimonials{
    .owl-nav {
      button.owl-next {
        right: -20px;
      }
      button.owl-prev {
        left: -20px;
      }
    }
  }
  .other-car-area{
    .owl-nav {
      button.owl-next {
        right: -20px;
      }
      button.owl-prev {
        left: -20px;
      }
    }
  }
  .car-details-posts-inner{
    .single_help_section_inner.single_help_section_inner_right{
      .position-content-car{
        div.content_single_help{
          margin-left: 0;
          margin-right: 0;
          border-radius: 0;
          padding: 20px;
        }
      }

    }
  }


  .car-details-posts-inner{
    .single_help_section_inner{
      margin-bottom: 20px;
    }
    .position-content-car{
      div.content_single_help{
        margin-right: 0;
        border-radius: 0;
        padding: 20px;
        h2{
          margin: 0 0 10px 0;
        }
        p {
          font-size: 12px;
        }
      }
    }

    .btn-book-now {
      padding: 10px 10px;
      font-size: 10px;
      margin-top: 10px;
    }
  }

  .single-product-area{
    .upper-product-images {
      width: 100%;
      float: none;
      margin-right: 0;
      margin-bottom: 20px;
    }
    .upper-product-description {
      float: none;
      width: 100%;
      margin-left: 0;
    }
  }




  .product-info-area {
    .product-content h2 {
      font-weight: 700;
      margin-top: 0px;
      font-size: 24px;
    }
    .delivery-info{
      .single-delivery-info{
        h4{
          font-size: 16px;
        }
      }
    }
    .product-details-content {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .product-offers-buy .product-buy-one-get-one h2 {
      font-size: 14px;
    }
    .product-offers-buy .product-best-offer h4 {
      margin: 0px 0;
      font-size: 14px;
    }
    .book-now-area {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .order-to-discount-area{
    .single-order-box{
      margin-bottom: 30px;
      .single-order-box-inner{

      }
    }
  }


}
/* Mobile Layout: 320px. */

@media only screen and (max-width: 767px) {

  .founder-info {
    width: 100%;
    .founder-description{
      &:before {
        position: absolute;
        left: -14px;
      }
    }
  }
  .header-area{
    .navigation-area{
      ul{
        margin:0;
        padding: 0;
        list-style: none;
        li{
          margin: 0 12px;
          a{
            font-size: 14px;
          }
        }
      }
    }

  }

  .banner-section,.car-details-hero-area{
    .overlay_text{
      h2{
        font-size: 24px;
        margin-bottom: 15px;
      }
      p{
        font-size: 12px;
      }
      .btn-book-now {
        margin-top: 15px;
      }
    }
  }

  .quick-access{
    padding-top:0;
    &:before {
      height: 25px;
    }
    .steps-list{
      margin-top: 50px;
      .lambo-car{
        position: absolute;
        bottom: -95px;
        right: 0;
        width: 250px;
      }
      ul{
        li{
          margin-bottom: 20px;
        }
      }
    }
  }
  .offer-area{
    padding: 50px 0 120px;
    &:before{
      height: 0;
    }
  }
  .offer-inner-area{
    img{
      width: 100%;
    }
    .insurance-offer {
      position: inherit;
      transform: inherit;
      -moz-transform: inherit;
      -webkit-transform: inherit;
      -o-transform: inherit;
      margin-bottom: 50px;

    }
    .cars{
      bottom: -100px;
      left: 0;
      width: 300px;
    }
  }

  .promotion-area {
    padding: 90px 0 80px;
  }

  .tb-space {
    margin-bottom: 30px;
  }


  .testimonials-carousals-area {
    position: relative;

    .testimonials-carousals-inner {
      .single-testimonials {
        box-shadow: 0 0 20px #ddd;
        border-radius: 20px;
        padding: 90px 40px 50px;
        margin: 110px 20px 20px 20px;
        position: relative;
        opacity: 1;
        .author-thum {
          position: absolute;
          top: -95px;
          left: 50%;
          transform: translate(-50%, 0);
          -moz-transform: translate(-50%, 0);
          -webkit-transform: translate(-50%, 0);
          -ms-transform: translate(-50%, 0);
          -o-transform: translate(-50%, 0);
        }
        img {
          width: auto;
          margin: 0 auto;
        }
        h3 {
          &.author-name {
            font-size: 18px;
            font-weight: 700;
            margin: 20px 0;
            color: $primary-color2;
          }
          p {
            font-size: 20px;
          }
        }
      }
    }
  }
  .about-suprim-area{
    .about-details{
      &:before {
        position: inherit;
      }
    }
  }

  .brands-area{
    .owl-nav{
      button.owl-next{
        right: 0;
        margin-right: 0;
      }
      button.owl-prev{
        left: 0;
        margin-left: 0;
      }
    }
  }

  .header-area{
    .logo{
     max-width:150px;
    }
    .navigation-area{
      &.desktop-menu{
        display: none !important;
      }

    }


  }

.mobile-icon{
  display: block;
}

  .mobile-menu-nav{
    position: absolute;
    z-index: 999;
    width: 100%;
    background: rgba(220,217,217,0.9);
    padding: 60px 32px;
    top: 74px;
    height: 92vh;
    display: none;
    .navigation-area{
      ul{
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: center;
        li{
          display: block;
          padding: 14px 0;
          &:last-child{
            a{
              color: $primary-color2;
              border: 1px solid $primary-color2;
              padding: 5px 20px;
              border-radius: 5px;

            }
            margin-right:0;
          }
          a{
            display: inline-block;
            color:$secondary-color1;
            font-size: 20px;
            text-decoration: none;
            font-weight: 700;
            &:hover{
              color: $primary-color2;
            }
          }
        }
      }
    }
  }
  .banner-section{
    .overlay_text{
      position: absolute;
      top: 10%;
      left: 50%;
      -ms-transform: translate(-50%, -10%);
      transform: translate(-50%, -10%);
      -moz-transform: translate(-50%, -10%);
      -webkit-transform: translate(-50%, -10%);
      width: 100%;
      text-align: center;
      p{
        display: none;
      }
    }
  }

  .brands-area {
    padding: 30px 0;
  }

  .promotion-area{
    .col-sm-4 {
      margin-bottom: 60px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  .section-title {
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 35px;
    span:before {
      height: 3px;
      width: 20px;
      left: -22px;
    }
    span:after {
      height: 3px;
      width: 20px;
      right: -22px;
    }
  }

  .single-car-select-box{
    .single-car-select-box-title-img{
      h2{
        font-size: 16px;
      }
      img{
        max-width: 250px;
      }
    }
    .single-car-features{
      ul{
        li{
          float: none;
          margin-bottom: 22px;
          width:100%;
        }
      }
    }
    .select-car-button {
      margin-top: 10px;
    }
  }
  .about-suprim-area{
    padding: 50px 0;
    .title-image{
      h2 {
        font-size: 20px;
      }
    }
    .about-details{
      h2{
        &.section-title {
          font-size: 20px;
          margin-top: 0;
        }
      }
      .list-section{
        .single-list-section{
          .left-list {
            margin-right: 10px;
          }
          .right-list {
            width: 80%;
          }
        }
      }
    }
  }

  .drive-dream-section{
    .get-started-button {
      text-align: center;
    }
  }
  .footer-menu {
    ul{
      li{
        a{
          display: block;
          margin-right: 0;
          margin-bottom: 15px;
        }
      }
    }
  }


  .how-it-works-area{
    .how-it-works-inner{
      #timeline {
        margin: 30px;
        padding: 0;
        width: 90%;
        &:before {
          left: 0;
        }
        .timeline-item {
          .timeline-content {
            width: 90%;
            float: right;
            &:before, &.right:before {
              left: 10%;
              margin-left: -13px;
              border-left: 0;
              border-right: 15px solid $secondary-color2;
            }
          }
          .timeline-icon {
            left: 0;
          }
        }
      }
    }
  }

  .car-details-hero-area{

    #thumbs {
      padding: 10px 20px;
    }
    .overlay_text{
      top:inherit;
      transform: inherit;
      -moz-transform: inherit;
      -webkit-transform: inherit;
      -o-transform: inherit;
      bottom: 30%;
      text-align: center;
      left: 0;
      width: 100%;
    }
  }

  .car-price-details-area{
    .car-specification{
      h2 {
        font-size: 24px;
        text-align: center;
      }
      p{
        text-align: center;
      }
    }
    .car-box-detail {
      margin-top: 30px;
    }
  }

  .how-it-works-area {
    padding: 0px 0;
  }

  .testimonals-area-car-details-page {
    padding: 0 0 30px;
  }
  .other-car-area {
    padding: 0 0 30px;
  }

  .all-car-page-testimonials{
    .owl-nav {
      button.owl-next {
        right: 0;
        height: 25px;
        width: 25px;
        font-size: 15px;
      }
      button.owl-prev {
        left: 0;
        height: 25px;
        width: 25px;
        font-size: 15px;
      }
    }

  }
  .other-car-area{
    .owl-nav {
      button.owl-next {
        right: 0;
        top: inherit;
        transform: inherit;
        -moz-transform: inherit;
        -webkit-transform: inherit;
        background-color: inherit;
        color: $primary-color2;
        width: auto;
        height: auto;
      }
      button.owl-prev {
        left: 0;
        top: inherit;
        transform: inherit;
        -moz-transform: inherit;
        -webkit-transform: inherit;
        background-color: inherit;
        color: $primary-color2;
        width: auto;
        height: auto;
      }
    }
    .owl-dots {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      -moz-transform: translate(-50%, 0);
      -webkit-transform: translate(-50%, 0);
      -o-transform: translate(-50%, 0);
      margin-top: 10px;
      .owl-dot {
        width: 15px;
        height: 15px;
        background: #ddd;
        border-radius: 500px;
        margin: 0 3px;
        &.active {
          width: 18px;
          height: 18px;
          background-color: $primary-color1;
        }
      }
    }
  }

  .car-details-posts-area{
    margin-top: 50px;
  }

  .car-details-posts-inner{
    .single_help_section_inner{
      margin-bottom: 20px;
      img{
        width: 100%;
      }
    }
    .position-content-car{
      margin-right: 0;
      width: 100%;
      left: 0;
      position: inherit;
      margin-top: 10px;
      transform: inherit;
      -moz-transform: inherit;
      -webkit-transform: inherit;
      .content_single_help{
        border-radius: 0 0 20px 20px !important;
        h2{
          margin: 0 0 10px 0;
        }
        p {
          font-size: 12px;
        }
      }
      .btn-book-now {
        padding: 10px 10px;
        margin: 10px !important;
        float: none;
        display: block;
        text-align: center;
        text-transform: uppercase;
        font-size: 16px;
      }
    }


  }

  //.car-details-posts-inner .single_help_section_inner.single_help_section_inner_right .position-content-car div.content_single_help {
  //  left: 0;
  //  right: inherit;
  //  margin-left: 0;
  //  margin-right: 0;
  //  position: inherit;
  //  transform: inherit;
  //  -moz-transform: inherit;
  //  -webkit-transform: inherit;
  //  border-radius: 0;
  //  margin-top: 10px;
  //}
  .car-price-details-area{
    .single-specification{
      .spec-details h4 {
        font-size: 12px;
      }
    }
  }
  .other-car-area{
    .single-car-select-box{
      .single-car-select-box-title-img img {

      }
      .select-car-button{
        display: block;
        margin-left:20px;
        margin-right: 20px;
      }
    }
  }
  .how-it-works-area {
    .how-it-works-inner {
      .timeline-content .icon-img {
        float: none;
        width: 100%;
        margin-right: 0;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }

  .banner-section-inner.banner-for-merchandise{
    .overlay_text {
      left: 50%;
      right: inherit;
    }
  }

  .single-product-area{
    .upper-product-images {
      width: 100%;
      float: none;
      margin-right: 0;
      margin-bottom: 20px;
      .big-thumbnails{
        img{
          width: 100%;
          border-radius: 10px;
        }
      }
      .small-thumbnails {
        img{
          border-radius: 5px;
        }
      }
    }
    .upper-product-description {
      float: none;
      width: 100%;
      margin-left: 0;
    }
  }
  .new-arrivals-section {
    padding: 0 0;
  }
  .why-merchandise-area{
    margin-top: 0;
  }
  .big-thum,.delivery-info,.small-thum {
    display: none;
  }
  .product-info-area{
    .product-content h2 {
      font-size: 20px;
      text-align: center;
    }
    .product-review {
      text-align: center;
      .star-marking {
        float: none;
      }
      .product-review-numbers {
        float: none;
        margin-left: 0;
        font-size: 16px;
      }
    }
    .product-price-stock {
      margin-top: 20px;
      text-align: center;
      .product-price {
        float: none;
        width: 100%;
        background-color: inherit;
        h2{
          background-color: $primary-color3;
          padding: 10px 20px;
          display: inline-block;
        }
      }
      .product-stock {
        float: none;
        width: 100%;
        text-align: center;
        h4{
          margin: 0;
          padding: 5px 0px;
        }
      }
    }

    .product-details-content {
      margin-top: 30px;
      margin-bottom: 30px;
      text-align: center;
    }

    .product-offers-buy .product-buy-one-get-one h2 {
      font-size: 14px;
      margin: 5px 0 0 0;
      text-align: left;
    }
    .book-now-area {
      position: fixed;
      bottom: 0;
      margin: 0;
      left: 0;
      width: 100%;
      background: $secondary-color2;
      padding: 10px 20px;
      z-index: 99;
      border-radius: 10px 10px 0 0;
      border: 1px solid#ddd;
    }
    .review-prgress-bar .review-progress {
      margin-right: 1%;
      margin-bottom: 0;
      margin-top: 5px;
    }


  }

  .order-to-discount-area {
    .single-order-box {
      margin-bottom: 20px;
      .single-order-box-inner h2 {
        font-size: 14px;
      }
    }
  }

  .more-details-area {
    padding: 0px 0;
    margin-top: 0;
  }
  .mobile-delivery-info{
    display: block !important;
    img{
      max-width: 60px;
    }
    h4{
      font-size: 12px;
    }
  }

  .mobile-bigthum{
    display: block !important;
    .single-thum-product-details{
      opacity: 0.5;
      margin: 30px 0;
      img{
        border-radius: 6px;
      }
    }
    .center{
      .single-thum-product-details{
        opacity: 1;
        transform: scale(1.3);
        -moz-transform: scale(1.3);
        -webkit-transform: scale(1.3);
        -o-transform: scale(1.3);
        -ms-transform: scale(1.3);
      }
    }
    .owl-dots{
      text-align: center;
      margin: 20px 0;
      .owl-dot {
        width: 10px;
        height: 10px;
        background: #ddd;
        border-radius: 500px;
        margin: 0 10px;
        &.active {
          width: 30px;
          background-color: $primary-color2;
        }
      }
    }
  }

  .product-details-content p{
    font-size: 12px;
  }

  .single-order-box-content p{
    font-size: 12px;
  }

  .box__label {
    right: 10px;
    padding: 18px 5px;
    font-size: 12px;
    width: 50px;
    text-align: center;
    &:before {
      border-top: 40px solid transparent;
      border-bottom: 40px solid transparent;
      border-left: 10px solid #fff;
      left: 19px;
      bottom: -38px;
    }
  }



}


/* Wide Mobile Layout: 480px. */

@media only screen and (min-width: 480px) and (max-width: 767px) {



}